
















import { ref } from '@vue/composition-api';

export default {
  name: 'MWelcomeLabel',
  props: {
    authType: {
      type: String,
      default: 'signup'
    },
    isIconShow: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any) {
    const classValue = ref();
    const iconValue = ref();
    const labelText = ref();
    const colorValue = ref();
    if (props.authType === 'login') {
      classValue.value = 'login__title info--text text-h4 font-weight-bold';
      iconValue.value = 'mdi-telegram';
      labelText.value = 'Login';
      colorValue.value = 'blue';
    } else if (props.authType === 'signup') {
      classValue.value = 'signup__title green--text text-h4 font-weight-bold';
      iconValue.value = 'mdi-heart-flash';
      labelText.value = 'Signup';
      colorValue.value = 'green';
    }
    return {
      classValue,
      iconValue,
      labelText,
      colorValue
    };
  }
};
